.component-statistique-view {
}
canvas#myChart1 {
  height: 60vh;
}

span.rdrInRange {
  color: #4a9c95c7 !important;
}
span.rdrEndEdge {
  color: #ff00007a !important;
}

span.rdrStartEdge {
  color: #ff00007a !important;
}

span.date {
  color: orange;
}
