.testy{
    max-width: 2.5rem;
}
#btn-phone{
    margin: 0;
    padding: 7px;
}
#point-me-formy .icon{
    min-height: auto !important;
}
#point-me-formy button#button-addon1 {
    padding: 3px !important;
}
#point-me-formy button.btn.btn-primary {
    width: 100%;
    font-weight: 600;
}
span.pm-checked-icon-container i {
    color: #009688;
    font-size: 20px;
}
#point-me-formy button.btn.btn-primary:hover, 
#point-me-formy button.btn.btn-primary:active {
    background: transparent;
    color: #009688 !important;
    border-color: #009688;
}
.col-md-4.offset-md-4.departure-point-container {
    text-align: center;
}
button.btn.btn-success.groupy-departure-point {
    background: #009688;
    border-color: #009688;
    width: 50%;
    padding-top: 10px;
    padding-bottom: 10px;
}
button.btn.btn-success.groupy-departure-point i {
    font-size: 20px;
    font-weight: 600;
}
button.close {
    right: 23px;
    position: absolute;
    display: inline-block;
    top: 33px;
}
h4#pointGroupyModalLabel {
    display: block;
    width: 100%;
    text-align: center
}
button.groupy-submit-btn {
    right: 15px; 
    position: absolute;
    background: #009688;
    border-color: #009688;
}
button.inprogress-btn{
    right: 15px; 
    position: absolute;
    background: #ff9800;
    border-color: #ff9800;
    color: #009688 !important;
}
button.groupy-submit-btn:hover{
    background: #009688 !important;
    border-color: #009688;
    /* color: #009688 !important; */
}