body,
html,
#root,
.login-clean {
  height: 100%;
}

.login-clean {
  background: rgb(0, 150, 136);
  background: linear-gradient(
    346deg,
    rgba(0, 150, 136, 1) 100%,
    rgba(255, 255, 255, 1) 100%,
    rgba(255, 152, 0, 1) 100%
  );
  padding: 25% 0;
}

.form {
  max-width: 320px;
  width: 90%;
  margin: 0 auto;
  /* background-color: #ffffff; */
  padding: 40px;
  border-radius: 4px;
  color: #505e6c;
  /* box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1); */
}

.illustration {
  text-align: center;
  padding: 0 0 20px;
  font-size: 27px;
  text-align: center;
  padding: 0 0 47px;
}
.illustration {
  font-size: 27px;
}

span.point-label {
  background: #009688;
  color: #fff;
  padding: 10px;
  font-weight: 600;
}

.logo_container {
  text-align: center;
}
span.me-label {
  color: #009688;
  font-weight: 600;
  background: #0096884d;
  padding: 10px 10px 10px 0;
}

form .form-control {
  background: #f7f9fc;
  border: none;
  border-bottom: 1px solid #dfe7f1;
  border-radius: 0;
  box-shadow: none;
  outline: none;
  color: inherit;
  text-indent: 8px;
  height: 42px;
}

form .btn-primary {
  background: #009688;
  border: none;
  border-radius: 4px;
  padding: 11px;
  box-shadow: none;
  margin-top: 26px;
  text-shadow: none;
  outline: none !important;
  line-height: 2;
}

form .btn-primary:hover,
form .btn-primary:active {
  background: transparent;
  border: 1px solid #009688;
  color: #009688 !important;
}

form .btn-primary:active {
  transform: translateY(1px);
}

form .forgot {
  display: block;
  text-align: center;
  font-size: 12px;
  color: #6f7a85;
  opacity: 0.9;
  text-decoration: none;
}

form .forgot:hover,
form .forgot:active {
  opacity: 1;
  text-decoration: none;
}

.login-input {
  width: 100%;
}

.ui.left.corner.label.login-label-icon-container:after {
  border-top-color: #009688 !important;
}

.ui.left.corner.label.login-label-icon-container i {
  font-size: 13px;
  left: -7px;
  color: #fff;
}
.component-connexion button.btn.btn-primary.btn-block,
.component-connexion button.btn.btn-primary.btn-block i {
  font-size: 14px;
  font-weight: 600 !important;
}

@media screen and (min-width: "320px") and (max-width: "720px") {
  .login-clean {
    padding: 62% 0;
  }
}

@media only screen and (max-width: 600px) {
  .login-clean {
    height: 100vh !important;
  }
}

.banner-connexion-page {
  width: 100%;
  background: linear-gradient(
      90deg,
      rgba(6, 154, 119, 0.7962535355939251) 35%,
      rgba(6, 154, 119, 0.8018557764902836) 100%
    ),
    url(../../assets/images/baks.png);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  /* padding: 4rem 8rem; */
}
.logo-connexion {
  width: 115px;
  margin-bottom: 90px;
}
.errorMsg {
  color: #cc0000;
  margin-bottom: 12px;
}
.titre-banner-connexion {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 88px;
  line-height: 99.5%;
  color: #ffffff;
}
.slogan-banner-connexion {
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 102%;
  letter-spacing: 0.12em;
  color: #ffffff;
}
.btn-connexion {
  background: #069a77;
  border-radius: 10px;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1em;
  color: #ffffff;
  padding: 15px;
  border: 1px solid #069a77;
  transition: 0.2s ease-in-out;
}
.btn-connexion:hover {
  background-color: #fff;
  color: #069a77;
  transition: 0.2s ease-in-out;
}

.btn-connexion-facebook:hover {
  background: rgb(83, 153, 245);
  color: #ffffff;
}
.btn-connexion-facebook {
  border-radius: 10px;
  /* width: 100%; */
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  padding: 10px;
  transition: 0.2s ease-in-out;
  font-size: 25px;
  line-height: 17px;
  letter-spacing: 0.1em;
  background-color: #fff;
  color: rgb(83, 153, 245);
  border: 1px solid rgb(83, 153, 245);

  transition: 0.2s ease-in-out;
}
.btn-connexion-google:hover {
  background: rgb(218, 50, 50);

  color: #ffffff;
}
.btn-connexion-google {
  border-radius: 10px;
  /* width: 100%; */
  padding: 10px;
  transition: 0.2s ease-in-out;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 17px;
  letter-spacing: 0.1em;
  background-color: #fff;
  color: rgb(218, 50, 50);
  border: 1px solid rgb(218, 50, 50);

  transition: 0.2s ease-in-out;
}
.mdp-forget-link {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  color: #069a77;
  transition: 0.2s ease-in-out;
  margin-bottom: 30px;
  text-align: right;
  display: block;
}
.mdp-forget-link:hover {
  transition: 0.2s ease-in-out;
  color: #069a77;
}
.h-connexion-form {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: 0.1em;
  color: #069a77;
  margin-bottom: 50px;
  text-align: center;
}
.icon-form-connexion {
  width: 15px;
}

.input-form-connexion {
  border-top: 1px solid #069a77;
  border-left: 0px;
  border-right: 1px solid #069a77;
  border-bottom: 1px solid #069a77;
  border-radius: 10px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.51);
  height: 50px;
}
.input-form-connexion:focus {
  border-top: 1px solid #069a77;
  border-left: 0px;
  border-right: 1px solid #069a77;
  border-bottom: 1px solid #069a77;
}
.input-group-text-connexion {
  background: transparent;
  color: #069a77;
  border: 1px solid #069a77;
  border-radius: 10px;
  height: 50px;
}
.loader {
  color: #069a77;
  background-color: #069a77;
  -webkit-box-shadow: 0 0.125rem 0.8rem rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0 0.125rem 0.8rem rgba(0, 0, 0, 0.1) !important;
}

/* Social Login */

.omb_loginOr {
  position: relative;
  font-size: 1em;
  color: #aaa;
  margin-top: 1em;
  margin-bottom: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.omb_loginOr .omb_hrOr {
  background-color: #cdcdcd;
  height: 1px;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.omb_loginOr .omb_spanOr {
  display: block;
  position: absolute;
  left: 37%;
  top: -0.6em;
  margin-left: -1.5em;
  background-color: white;
  width: 10em;
  text-align: center;
}
.icon-facebook-connexion {
  width: 30px;
}

.auth-error.text-center {
  color: red;
  padding: 0.5em;
}
