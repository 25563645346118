.calendar-container {
  border: none !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 1rem !important;
  margin-top: 1rem;
  border-radius: 1rem;
}

.bakeliste__details--title{
  font-size: 1.8rem;

}

.tileStyle {
  color: red;
}

.react-calendar__tile--now {
  background-color: #b8f1eb !important;
  border-radius: 2px !important;
}

.react-calendar__tile--range {
  background-color: #009688 !important;
  border-radius: 2px !important;
}

.presence-details {
  width: 1400px !important;
  margin: auto;
}

.span_detail--label, .span_detail--text{
    font-size: 0.98rem;
}

.span_detail--text{
    background-color: #2e7870;
    padding: 0.15rem 0.5rem;
    border-radius: 50px;
    margin-left: 0.5rem;
    color: white;
}

@media (max-width: 700px) {
  .presence-details {
    max-width: 95% !important;
  }
  .bakeliste__details--title{
    font-size: 1.2rem;
    
  }
}
