.component-graphe-absence {
}
.graphContainer {
  /* width: 50vw;*/
  /* height: auto; */
  background: white;
  padding: 30px 20px 10px 20px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 10px 0px -5px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 10px 0px -5px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 10px 0px -5px rgba(0, 0, 0, 0.3);
}

canvas#myChart {
  height: 60vh;
}

@media only screen and (max-width: 600px) {
  .graphContainer {
    width: 80vw;
  }
  canvas#myChart {
    height: 60vh;
  }
}
