.rdrDateRangePickerWrapper{
  display: block !important;
  /* background-color: red !important; */
  /* border: 1px solid !important; */
  padding-bottom: 0.5rem !important;
}

.rdrDateRangePickerWrapper, .rdrDefinedRangesWrapper{
  width: 100% !important;
  /* background-color: aqua !important; */
}

.rdrDefinedRangesWrapper{
  width: 100% !important;
  /* background-color: khaki !important; */
  /* display: none; */
}

.rdrInputRanges{
  display: none !important;
}

/* semaine, mois, annee wrapper */
.rdrStaticRanges{
  flex-direction: row !important;
}

/* ! calendar */
.rdrCalendarWrapper{
  width: 100% !important;
  /* display: none !important; */
  /* background-color: red !important; */
}

.rdrMonth{
  max-width: 500px !important;
  margin: auto;
  width: 100% !important;
  border: 1px solid rgb(177, 170, 170) !important;
}

/* !horizontal calendar */
.rdrDays{
  display: flex !important;
  flex-wrap: nowrap !important;
  overflow-x: scroll;
}

.rdrWeekDays{
  display: none !important;
}

.rdrDay{
  /* margin: 0 1rem !important; */
  /* background-color: green !important; */
  padding: 1rem !important;
}

.rdrDayNumbe {
  /* outline: 0; */
  /* font-weight: 300; */
  /* background-color: rgb(230, 222, 222); */
  position: static !important;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  top: 5px;
  bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem !important;
}

.span-pointer{
  cursor: pointer !important;
  font-weight: 800 !important;
  padding-top: 0.6rem !important;
  padding-bottom: 0.8rem !important;
  margin-left: 0.19rem !important;
}