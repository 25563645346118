.component-present-abent-graphe {
}

/* Reset Select */
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  box-shadow: none;
  border: 0 !important;
  background: #4b827c;
  background-image: none;
  /* font-family: "FontAwesome", "Second Font name"; */
}
/* Remove IE arrow */
select::-ms-expand {
  display: none;
}
/* Custom Select */
.select {
  position: relative;
  display: flex;
  /* width: 20em; */
  height: 3em;
  line-height: 3;
  background: #ffa507;
  overflow: hidden;
  border-radius: 0.25em;
}
select {
  flex: 1;
  padding: 0 0.5em;
  color: #fff;
  cursor: pointer;
}
/* Arrow */
.select::after {
  content: "\25BC";
  position: absolute;
  top: 0;
  right: 0;
  color: white;
  padding: 0 1em;
  background: #7cc6bf;
  cursor: pointer;
  pointer-events: none;
  transition: 0.25s all ease;
}

/* Transition */
.select:hover::after {
  color: #f39c12;
}

a.btn.btn-default {
  color: black;
  border: 1px solid #858688;
}
a.btn.btn-default:hover {
  color: orange;
  border: 1px solid #858688;
}

a.filter {
  border: 1px solid gray;
  padding: 5px;
  font-size: 21px;
}

.filter-text {
  padding: 2px;
  color: #717273;
  font-size: 13px;
}

/* From ..... */

:root {
  --bg: #e3e4e8;
  --fg: #17181c;
  --bs1: #ffffff;
  --bs2: #f3f4f8;
  --bs3: #cccdd1;
  --bs4: #c1c2c5;
  --transDur: 0.1s;
  /* font-size: calc(20px + (40 - 20) * (100vw - 320px) / (2560 - 320)); */
}
form.formm {
  display: flex;
}
input.inputt {
  color: var(--fg);
  font: 1em/1.5 Muli, sans-serif;
}

form.formm {
  margin: auto;
  max-width: 24em;
  padding: 0 1.5em;
}
label.labell {
  display: block;
  margin: 0 0.2em;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
label.labell:first-child input[type="radio"] {
  border-radius: 0.5em 0 0 0.5em;
}
label.labell:last-child input[type="radio"] {
  border-radius: 0 0.5em 0.5em 0;
}
input[type="radio"] {
  border-radius: 0;
  box-shadow: 0.15em 0.15em 1em var(--bs2) inset,
    -0.15em -0.15em 1em var(--bs3) inset, 0.15em 0.15em 0.45em #7cc6bf,
    -0.15em -0.15em 0.45em var(--bs1);
  cursor: pointer;
  display: block;
  margin-bottom: 0.5em;
  width: 100%;
  height: 1em;
  transition: all var(--transDur) ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
input[type="radio"]:checked {
  /* box-shadow: -0.15em -0.15em 0.45em #649e99 inset,
    0.15em 0.15em 0.45em #4b7571 inset, 0 0 0 #ffa507, 0 0 0 #4da59c; */
  /* box-shadow: -0.15em -0.15em 0.45em #649e99 inset, 0.15em 0.15em 0.45em #4b7571 inset, 0 0 0 #ffa507, 0 0 0 #4da59c; */
  background-image: linear-gradient(
    #ffffff 33%,
    #7db7b2 58%,
    #7cc6bf,
    #314442,
    #c3adaa
  );
  box-shadow: 0.1em 0 0 #7cc6bf inset, -0.1em 0 0 #7cc6bf inset,
    0 0.1em 0 #dabc75 inset, 0 -0.1em 0 #ffffff3f, 0 0.1em 0 #7cc6bf,
    0 -0.1em 0 #7cc6bf inset, -0.1em -0.2em 0 #ffffff7f inset;
}
input[type="radio"]:checked + span {
  opacity: 1;
}
input[type="radio"]:focus {
  outline: transparent;
}
input[type="radio"] + span {
  opacity: 0.65;
  transition: opacity var(--transDur) ease-in-out;
}

/* Dark mode */
@media (prefers-color-scheme: dark) {
  :root {
    --bg: #454954;
    --fg: #e3e4e8;
    --bs1: #5a5f6d;
    --bs2: #4a4e5a;
    --bs3: #3e424c;
    --bs4: #30333b;
  }
}
