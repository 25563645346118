body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*
=========================================
=========================================

   Bubbly Dashboard Template

=========================================
========================================= */
/*
*
* =====================
* GENERAL
* =====================
*
*/
.shadow {
  -webkit-box-shadow: 0 0.125rem 0.8rem rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0 0.125rem 0.8rem rgba(0, 0, 0, 0.1) !important;
}

.text-uppercase {
  letter-spacing: 0.2em;
}

.text-lg {
  font-size: 1.4rem !important;
}

.text-base {
  font-size: 0.9rem !important;
}

.text-sm {
  font-size: 0.7875rem !important;
}

.roundy {
  border-radius: 50px;
}

.border {
  border: 1px solid #eee;
}

.rounded {
  border-radius: 0.5rem !important;
}

.push {
  margin-left: 6rem;
}

.headings-font-family {
  font-family: "Poppins", sans-serif !important;
}

.text-gray {
  color: #adb5bd;
}

.dot {
  max-width: 10px;
  min-width: 10px;
  max-height: 10px;
  min-height: 10px;
  border-radius: 50%;
}

.dot-sm {
  max-width: 0.5rem;
  min-width: 0.5rem;
  max-height: 0.5rem;
  min-height: 0.5rem;
  border-radius: 50%;
}

a {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.page-holder {
  overflow-x: hidden;
  min-height: calc(100vh - 72px);
}

.icon {
  min-width: 2.2rem;
  max-width: 2.2rem;
  min-height: 2.2rem;
  max-height: 2.2rem;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.icon-lg {
  min-width: 3rem;
  max-width: 3rem;
  min-height: 3rem;
  max-height: 3rem;
}

.icon-sm {
  min-width: 1.7rem;
  max-width: 1.7rem;
  min-height: 1.7rem;
  max-height: 1.7rem;
  font-size: 0.7rem;
}

button {
  cursor: pointer;
  outline: none !important;
}

.smaller {
  font-size: 0.7rem;
}

.no-anchor-style {
  color: inherit !important;
  text-decoration: none !important;
}

.line {
  height: 1px;
  border-bottom: 1px dashed #eee;
  margin: 2rem 0;
}

.btn:not([class*='outline']) {
  color: #fff;
}

.btn:not([class*='outline']):hover, .btn:not([class*='outline']):focus {
  color: #fff !important;
}

.border-thick {
  border-width: 4px !important;
}

.no-shadow {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.input-group-text {
  border-radius: 2rem;
}

.bg-hover-gradient-primary {
  position: relative;
  overflow: hidden;
  z-index: 2;
}

.bg-hover-gradient-primary:hover {
  color: #fff !important;
}

.bg-hover-gradient-primary:hover [class*='text-']:not(.exclode) {
  color: #fff !important;
}

.bg-hover-gradient-primary:hover::before {
  opacity: 1;
}

.bg-hover-gradient-primary::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  background: -webkit-gradient(linear, right top, left top, from(#009688), to(#0046df)) !important;
  background: linear-gradient(to left, #009688, #0046df) !important;
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  z-index: -1;
}

.bg-hover-primary {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-primary:hover {
  background: #009688 !important;
  color: #fff !important;
}

.bg-hover-primary:hover [class*='text-']:not(.exclode) {
  color: #fff !important;
}

.gradient-primary {
  background: -webkit-gradient(linear, right top, left top, from(#009688), to(#0046df)) !important;
  background: linear-gradient(to left, #009688, #0046df) !important;
}

.bg-hover-gradient-secondary {
  position: relative;
  overflow: hidden;
  z-index: 2;
}

.bg-hover-gradient-secondary:hover {
  color: #fff !important;
}

.bg-hover-gradient-secondary:hover [class*='text-']:not(.exclode) {
  color: #fff !important;
}

.bg-hover-gradient-secondary:hover::before {
  opacity: 1;
}

.bg-hover-gradient-secondary::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  background: -webkit-gradient(linear, right top, left top, from(#6c757d), to(#3d4246)) !important;
  background: linear-gradient(to left, #6c757d, #3d4246) !important;
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  z-index: -1;
}

.bg-hover-secondary {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-secondary:hover {
  background: #6c757d !important;
  color: #fff !important;
}

.bg-hover-secondary:hover [class*='text-']:not(.exclode) {
  color: #fff !important;
}

.gradient-secondary {
  background: -webkit-gradient(linear, right top, left top, from(#6c757d), to(#3d4246)) !important;
  background: linear-gradient(to left, #6c757d, #3d4246) !important;
}

.bg-hover-gradient-success {
  position: relative;
  overflow: hidden;
  z-index: 2;
}

.bg-hover-gradient-success:hover {
  color: #fff !important;
}

.bg-hover-gradient-success:hover [class*='text-']:not(.exclode) {
  color: #fff !important;
}

.bg-hover-gradient-success:hover::before {
  opacity: 1;
}

.bg-hover-gradient-success::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  background: -webkit-gradient(linear, right top, left top, from(#28a745), to(#145523)) !important;
  background: linear-gradient(to left, #28a745, #145523) !important;
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  z-index: -1;
}

.bg-hover-success {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-success:hover {
  background: #28a745 !important;
  color: #fff !important;
}

.bg-hover-success:hover [class*='text-']:not(.exclode) {
  color: #fff !important;
}

.gradient-success {
  background: -webkit-gradient(linear, right top, left top, from(#28a745), to(#145523)) !important;
  background: linear-gradient(to left, #28a745, #145523) !important;
}

.bg-hover-gradient-info {
  position: relative;
  overflow: hidden;
  z-index: 2;
}

.bg-hover-gradient-info:hover {
  color: #fff !important;
}

.bg-hover-gradient-info:hover [class*='text-']:not(.exclode) {
  color: #fff !important;
}

.bg-hover-gradient-info:hover::before {
  opacity: 1;
}

.bg-hover-gradient-info::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  background: -webkit-gradient(linear, right top, left top, from(#17a2b8), to(#0c525d)) !important;
  background: linear-gradient(to left, #17a2b8, #0c525d) !important;
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  z-index: -1;
}

.bg-hover-info {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-info:hover {
  background: #17a2b8 !important;
  color: #fff !important;
}

.bg-hover-info:hover [class*='text-']:not(.exclode) {
  color: #fff !important;
}

.gradient-info {
  background: -webkit-gradient(linear, right top, left top, from(#17a2b8), to(#0c525d)) !important;
  background: linear-gradient(to left, #17a2b8, #0c525d) !important;
}

.bg-hover-gradient-warning {
  position: relative;
  overflow: hidden;
  z-index: 2;
}

.bg-hover-gradient-warning:hover {
  color: #fff !important;
}

.bg-hover-gradient-warning:hover [class*='text-']:not(.exclode) {
  color: #fff !important;
}

.bg-hover-gradient-warning:hover::before {
  opacity: 1;
}

.bg-hover-gradient-warning::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  background: -webkit-gradient(linear, right top, left top, from(#ffc107), to(#a07800)) !important;
  background: linear-gradient(to left, #ffc107, #a07800) !important;
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  z-index: -1;
}

.bg-hover-warning {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-warning:hover {
  background: #ffc107 !important;
  color: #fff !important;
}

.bg-hover-warning:hover [class*='text-']:not(.exclode) {
  color: #fff !important;
}

.gradient-warning {
  background: -webkit-gradient(linear, right top, left top, from(#ffc107), to(#a07800)) !important;
  background: linear-gradient(to left, #ffc107, #a07800) !important;
}

.bg-hover-gradient-danger {
  position: relative;
  overflow: hidden;
  z-index: 2;
}

.bg-hover-gradient-danger:hover {
  color: #fff !important;
}

.bg-hover-gradient-danger:hover [class*='text-']:not(.exclode) {
  color: #fff !important;
}

.bg-hover-gradient-danger:hover::before {
  opacity: 1;
}

.bg-hover-gradient-danger::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  background: -webkit-gradient(linear, right top, left top, from(#dc3545), to(#921925)) !important;
  background: linear-gradient(to left, #dc3545, #921925) !important;
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  z-index: -1;
}

.bg-hover-danger {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-danger:hover {
  background: #dc3545 !important;
  color: #fff !important;
}

.bg-hover-danger:hover [class*='text-']:not(.exclode) {
  color: #fff !important;
}

.gradient-danger {
  background: -webkit-gradient(linear, right top, left top, from(#dc3545), to(#921925)) !important;
  background: linear-gradient(to left, #dc3545, #921925) !important;
}

.bg-hover-gradient-light {
  position: relative;
  overflow: hidden;
  z-index: 2;
}

.bg-hover-gradient-light:hover {
  color: #fff !important;
}

.bg-hover-gradient-light:hover [class*='text-']:not(.exclode) {
  color: #fff !important;
}

.bg-hover-gradient-light:hover::before {
  opacity: 1;
}

.bg-hover-gradient-light::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  background: -webkit-gradient(linear, right top, left top, from(#f8f9fa), to(#bdc6d0)) !important;
  background: linear-gradient(to left, #f8f9fa, #bdc6d0) !important;
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  z-index: -1;
}

.bg-hover-light {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-light:hover {
  background: #f8f9fa !important;
  color: #fff !important;
}

.bg-hover-light:hover [class*='text-']:not(.exclode) {
  color: #fff !important;
}

.gradient-light {
  background: -webkit-gradient(linear, right top, left top, from(#f8f9fa), to(#bdc6d0)) !important;
  background: linear-gradient(to left, #f8f9fa, #bdc6d0) !important;
}

.bg-hover-gradient-violet {
  position: relative;
  overflow: hidden;
  z-index: 2;
}

.bg-hover-gradient-violet:hover {
  color: #fff !important;
}

.bg-hover-gradient-violet:hover [class*='text-']:not(.exclode) {
  color: #fff !important;
}

.bg-hover-gradient-violet:hover::before {
  opacity: 1;
}

.bg-hover-gradient-violet::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  background: -webkit-gradient(linear, right top, left top, from(#DF99CA), to(#c74ba2)) !important;
  background: linear-gradient(to left, #DF99CA, #c74ba2) !important;
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  z-index: -1;
}

.bg-hover-violet {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-violet:hover {
  background: #DF99CA !important;
  color: #fff !important;
}

.bg-hover-violet:hover [class*='text-']:not(.exclode) {
  color: #fff !important;
}

.gradient-violet {
  background: -webkit-gradient(linear, right top, left top, from(#DF99CA), to(#c74ba2)) !important;
  background: linear-gradient(to left, #DF99CA, #c74ba2) !important;
}

.bg-hover-gradient-blue {
  position: relative;
  overflow: hidden;
  z-index: 2;
}

.bg-hover-gradient-blue:hover {
  color: #fff !important;
}

.bg-hover-gradient-blue:hover [class*='text-']:not(.exclode) {
  color: #fff !important;
}

.bg-hover-gradient-blue:hover::before {
  opacity: 1;
}

.bg-hover-gradient-blue::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  background: -webkit-gradient(linear, right top, left top, from(#4C84FF), to(#0048e5)) !important;
  background: linear-gradient(to left, #4C84FF, #0048e5) !important;
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  z-index: -1;
}

.bg-hover-blue {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-blue:hover {
  background: #4C84FF !important;
  color: #fff !important;
}

.bg-hover-blue:hover [class*='text-']:not(.exclode) {
  color: #fff !important;
}

.gradient-blue {
  background: -webkit-gradient(linear, right top, left top, from(#4C84FF), to(#0048e5)) !important;
  background: linear-gradient(to left, #4C84FF, #0048e5) !important;
}

.bg-hover-gradient-green {
  position: relative;
  overflow: hidden;
  z-index: 2;
}

.bg-hover-gradient-green:hover {
  color: #fff !important;
}

.bg-hover-gradient-green:hover [class*='text-']:not(.exclode) {
  color: #fff !important;
}

.bg-hover-gradient-green:hover::before {
  opacity: 1;
}

.bg-hover-gradient-green::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  background: -webkit-gradient(linear, right top, left top, from(#7CF29C), to(#1fe956)) !important;
  background: linear-gradient(to left, #7CF29C, #1fe956) !important;
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  z-index: -1;
}

.bg-hover-green {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-green:hover {
  background: #7CF29C !important;
  color: #fff !important;
}

.bg-hover-green:hover [class*='text-']:not(.exclode) {
  color: #fff !important;
}

.gradient-green {
  background: -webkit-gradient(linear, right top, left top, from(#7CF29C), to(#1fe956)) !important;
  background: linear-gradient(to left, #7CF29C, #1fe956) !important;
}

.bg-hover-gradient-red {
  position: relative;
  overflow: hidden;
  z-index: 2;
}

.bg-hover-gradient-red:hover {
  color: #fff !important;
}

.bg-hover-gradient-red:hover [class*='text-']:not(.exclode) {
  color: #fff !important;
}

.bg-hover-gradient-red:hover::before {
  opacity: 1;
}

.bg-hover-gradient-red::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  background: -webkit-gradient(linear, right top, left top, from(#F0404C), to(#bb0f1a)) !important;
  background: linear-gradient(to left, #F0404C, #bb0f1a) !important;
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  z-index: -1;
}

.bg-hover-red {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-red:hover {
  background: #F0404C !important;
  color: #fff !important;
}

.bg-hover-red:hover [class*='text-']:not(.exclode) {
  color: #fff !important;
}

.gradient-red {
  background: -webkit-gradient(linear, right top, left top, from(#F0404C), to(#bb0f1a)) !important;
  background: linear-gradient(to left, #F0404C, #bb0f1a) !important;
}

.bg-hover-gradient-gray-100 {
  position: relative;
  overflow: hidden;
  z-index: 2;
}

.bg-hover-gradient-gray-100:hover {
  color: #fff !important;
}

.bg-hover-gradient-gray-100:hover [class*='text-']:not(.exclode) {
  color: #fff !important;
}

.bg-hover-gradient-gray-100:hover::before {
  opacity: 1;
}

.bg-hover-gradient-gray-100::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  background: -webkit-gradient(linear, right top, left top, from(#f8f9fa), to(#bdc6d0)) !important;
  background: linear-gradient(to left, #f8f9fa, #bdc6d0) !important;
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  z-index: -1;
}

.bg-hover-gray-100 {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-gray-100:hover {
  background: #f8f9fa !important;
  color: #fff !important;
}

.bg-hover-gray-100:hover [class*='text-']:not(.exclode) {
  color: #fff !important;
}

.gradient-gray-100 {
  background: -webkit-gradient(linear, right top, left top, from(#f8f9fa), to(#bdc6d0)) !important;
  background: linear-gradient(to left, #f8f9fa, #bdc6d0) !important;
}

.bg-hover-gradient-gray-200 {
  position: relative;
  overflow: hidden;
  z-index: 2;
}

.bg-hover-gradient-gray-200:hover {
  color: #fff !important;
}

.bg-hover-gradient-gray-200:hover [class*='text-']:not(.exclode) {
  color: #fff !important;
}

.bg-hover-gradient-gray-200:hover::before {
  opacity: 1;
}

.bg-hover-gradient-gray-200::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  background: -webkit-gradient(linear, right top, left top, from(#e9ecef), to(#aeb9c4)) !important;
  background: linear-gradient(to left, #e9ecef, #aeb9c4) !important;
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  z-index: -1;
}

.bg-hover-gray-200 {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-gray-200:hover {
  background: #e9ecef !important;
  color: #fff !important;
}

.bg-hover-gray-200:hover [class*='text-']:not(.exclode) {
  color: #fff !important;
}

.gradient-gray-200 {
  background: -webkit-gradient(linear, right top, left top, from(#e9ecef), to(#aeb9c4)) !important;
  background: linear-gradient(to left, #e9ecef, #aeb9c4) !important;
}

.bg-hover-gradient-gray-300 {
  position: relative;
  overflow: hidden;
  z-index: 2;
}

.bg-hover-gradient-gray-300:hover {
  color: #fff !important;
}

.bg-hover-gradient-gray-300:hover [class*='text-']:not(.exclode) {
  color: #fff !important;
}

.bg-hover-gradient-gray-300:hover::before {
  opacity: 1;
}

.bg-hover-gradient-gray-300::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  background: -webkit-gradient(linear, right top, left top, from(#dee2e6), to(#a4afba)) !important;
  background: linear-gradient(to left, #dee2e6, #a4afba) !important;
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  z-index: -1;
}

.bg-hover-gray-300 {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-gray-300:hover {
  background: #dee2e6 !important;
  color: #fff !important;
}

.bg-hover-gray-300:hover [class*='text-']:not(.exclode) {
  color: #fff !important;
}

.gradient-gray-300 {
  background: -webkit-gradient(linear, right top, left top, from(#dee2e6), to(#a4afba)) !important;
  background: linear-gradient(to left, #dee2e6, #a4afba) !important;
}

.bg-hover-gradient-gray-400 {
  position: relative;
  overflow: hidden;
  z-index: 2;
}

.bg-hover-gradient-gray-400:hover {
  color: #fff !important;
}

.bg-hover-gradient-gray-400:hover [class*='text-']:not(.exclode) {
  color: #fff !important;
}

.bg-hover-gradient-gray-400:hover::before {
  opacity: 1;
}

.bg-hover-gradient-gray-400::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  background: -webkit-gradient(linear, right top, left top, from(#ced4da), to(#94a1ae)) !important;
  background: linear-gradient(to left, #ced4da, #94a1ae) !important;
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  z-index: -1;
}

.bg-hover-gray-400 {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-gray-400:hover {
  background: #ced4da !important;
  color: #fff !important;
}

.bg-hover-gray-400:hover [class*='text-']:not(.exclode) {
  color: #fff !important;
}

.gradient-gray-400 {
  background: -webkit-gradient(linear, right top, left top, from(#ced4da), to(#94a1ae)) !important;
  background: linear-gradient(to left, #ced4da, #94a1ae) !important;
}

.bg-hover-gradient-gray-500 {
  position: relative;
  overflow: hidden;
  z-index: 2;
}

.bg-hover-gradient-gray-500:hover {
  color: #fff !important;
}

.bg-hover-gradient-gray-500:hover [class*='text-']:not(.exclode) {
  color: #fff !important;
}

.bg-hover-gradient-gray-500:hover::before {
  opacity: 1;
}

.bg-hover-gradient-gray-500::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  background: -webkit-gradient(linear, right top, left top, from(#adb5bd), to(#748290)) !important;
  background: linear-gradient(to left, #adb5bd, #748290) !important;
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  z-index: -1;
}

.bg-hover-gray-500 {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-gray-500:hover {
  background: #adb5bd !important;
  color: #fff !important;
}

.bg-hover-gray-500:hover [class*='text-']:not(.exclode) {
  color: #fff !important;
}

.gradient-gray-500 {
  background: -webkit-gradient(linear, right top, left top, from(#adb5bd), to(#748290)) !important;
  background: linear-gradient(to left, #adb5bd, #748290) !important;
}

.bg-hover-gradient-dark {
  position: relative;
  overflow: hidden;
  z-index: 2;
}

.bg-hover-gradient-dark:hover {
  color: #fff !important;
}

.bg-hover-gradient-dark:hover [class*='text-']:not(.exclode) {
  color: #fff !important;
}

.bg-hover-gradient-dark:hover::before {
  opacity: 1;
}

.bg-hover-gradient-dark::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  background: -webkit-gradient(linear, right top, left top, from(#343a40), to(#060708)) !important;
  background: linear-gradient(to left, #343a40, #060708) !important;
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  z-index: -1;
}

.bg-hover-dark {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-dark:hover {
  background: #343a40 !important;
  color: #fff !important;
}

.bg-hover-dark:hover [class*='text-']:not(.exclode) {
  color: #fff !important;
}

.gradient-dark {
  background: -webkit-gradient(linear, right top, left top, from(#343a40), to(#060708)) !important;
  background: linear-gradient(to left, #343a40, #060708) !important;
}

.bg-primary .checkbox-template::before {
  border: 1px solid #ddd !important;
}

.bg-primary .checkbox-template::after {
  background: #fff !important;
}

.bg-secondary .checkbox-template::before {
  border: 1px solid #ddd !important;
}

.bg-secondary .checkbox-template::after {
  background: #fff !important;
}

.bg-success .checkbox-template::before {
  border: 1px solid #ddd !important;
}

.bg-success .checkbox-template::after {
  background: #fff !important;
}

.bg-info .checkbox-template::before {
  border: 1px solid #ddd !important;
}

.bg-info .checkbox-template::after {
  background: #fff !important;
}

.bg-warning .checkbox-template::before {
  border: 1px solid #ddd !important;
}

.bg-warning .checkbox-template::after {
  background: #fff !important;
}

.bg-danger .checkbox-template::before {
  border: 1px solid #ddd !important;
}

.bg-danger .checkbox-template::after {
  background: #fff !important;
}

.bg-light .checkbox-template::before {
  border: 1px solid #ddd !important;
}

.bg-light .checkbox-template::after {
  background: #fff !important;
}

.bg-violet .checkbox-template::before {
  border: 1px solid #ddd !important;
}

.bg-violet .checkbox-template::after {
  background: #fff !important;
}

.bg-blue .checkbox-template::before {
  border: 1px solid #ddd !important;
}

.bg-blue .checkbox-template::after {
  background: #fff !important;
}

.bg-green .checkbox-template::before {
  border: 1px solid #ddd !important;
}

.bg-green .checkbox-template::after {
  background: #fff !important;
}

.bg-red .checkbox-template::before {
  border: 1px solid #ddd !important;
}

.bg-red .checkbox-template::after {
  background: #fff !important;
}

.bg-gray-100 .checkbox-template::before {
  border: 1px solid #ddd !important;
}

.bg-gray-100 .checkbox-template::after {
  background: #fff !important;
}

.bg-gray-200 .checkbox-template::before {
  border: 1px solid #ddd !important;
}

.bg-gray-200 .checkbox-template::after {
  background: #fff !important;
}

.bg-gray-300 .checkbox-template::before {
  border: 1px solid #ddd !important;
}

.bg-gray-300 .checkbox-template::after {
  background: #fff !important;
}

.bg-gray-400 .checkbox-template::before {
  border: 1px solid #ddd !important;
}

.bg-gray-400 .checkbox-template::after {
  background: #fff !important;
}

.bg-gray-500 .checkbox-template::before {
  border: 1px solid #ddd !important;
}

.bg-gray-500 .checkbox-template::after {
  background: #fff !important;
}

.bg-dark .checkbox-template::before {
  border: 1px solid #ddd !important;
}

.bg-dark .checkbox-template::after {
  background: #fff !important;
}

.credit-card {
  padding-top: 66%;
  height: 0;
  position: relative;
}

.credit-card .content {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

/*
*
* =====================
* NAVBAR
* =====================
*
*/
.navbar .dropdown-toggle::after {
  display: none;
}

.navbar .dropdown-menu {
  left: auto;
  top: 101%;
  right: 0;
  border: none;
}

.navbar .navbar-brand {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@media (max-width: 991.98px) {
  .navbar .navbar-brand {
    position: static;
    -webkit-transform: none;
    transform: none;
  }
}

.notification-icon {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background: #009688;
  position: absolute;
  top: 0.4rem;
  right: 0;
}

/*
*
* =====================
* SIDEBAR
* =====================
*
*/
.sidebar {
  width: 16rem;
  background: #fff;
  -webkit-box-shadow: 0 0.125rem 0.8rem rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.125rem 0.8rem rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.sidebar.shrink {
  width: 6rem;
  text-align: center;
}

.sidebar.shrink .sidebar-link {
  display: block;
}

.sidebar.shrink .sidebar-link span {
  display: block;
  font-size: 0.75rem;
}

.sidebar.shrink .sidebar-link i {
  margin: 0 !important;
}

.sidebar.shrink .collapse .sidebar-link, .sidebar.shrink .collapsing .sidebar-link {
  -webkit-transition: none;
  transition: none;
  padding-left: 0.2rem !important;
  padding-right: 0.2rem !important;
}

@media (max-width: 1199.98px) {
  .sidebar {
    width: 6rem;
    text-align: center;
    margin-left: -6rem;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
  }
  .sidebar .sidebar-link {
    display: block;
  }
  .sidebar .sidebar-link span {
    display: block;
    font-size: 0.75rem;
  }
  .sidebar .sidebar-link i {
    margin: 0 !important;
  }
}

.sidebar.show {
  margin-left: 0;
}

.sidebar-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
  padding: 0.5rem 1.5rem;
}

.sidebar-link[data-toggle="collapse"] {
  position: relative;
}

.sidebar-link[data-toggle="collapse"]::before {
  display: block;
  position: absolute;
  top: 50%;
  right: 1rem;
  color: #aaa;
  font-family: 'Font Awesome 5 Free';
  content: '\f104';
  font-weight: 900;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.sidebar-link[data-toggle="collapse"][aria-expanded='true']::before {
  content: '\f107';
}

.sidebar-link:hover {
  text-decoration: none;
  background: #f5f5f5;
}

.sidebar-link.active, .sidebar-link:focus {
  background: #009688;
  color: #fff !important;
  text-decoration: none;
}

.sidebar-link.active::before, .sidebar-link:focus::before {
  color: #fff;
}

.sidebar-link.active i, .sidebar-link:focus i {
  color: #fff !important;
}

.sidebar-link i {
  font-size: 1.5rem;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.sidebar-link span {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  display: inline-block;
}

.overflow-hidden {
  overflow: hidden !important;
}

.form-control-label {
  font-size: .9rem;
  color: #6c757d;
}

/*

=====================
STYLE SWITCHER FOR DEMO
=====================

*/
#style-switch-button {
  position: fixed;
  top: 150px;
  right: 0px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  z-index: 2;
}

#style-switch {
  width: 300px;
  padding: 20px;
  position: fixed;
  top: 200px;
  right: 0;
  background: #fff;
  border: solid 1px #e9ecef;
  z-index: 2000;
}

#style-switch h4 {
  color: #495057;
}

/* =========================================

      THEMING OF THE BOOTSTRAP COMPONENTS

   =========================================

    1 - NAVBAR
    2 - BUTTONS
    3 - TYPE
    4 - PAGINATION
    5 - UTILITIES
    6 - FORMS
    7 - CODE
    8 - NAV
    9 - CARD
    10 - DROPDOWNS

*/
/*
 * 1. NAVBAR
 */
.navbar {
  padding: 0.5rem 1rem;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.33125rem;
  padding-bottom: 0.33125rem;
  margin-right: 1rem;
  font-size: 1.125rem;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.125rem;
  line-height: 1;
  border: 1px solid transparent;
  border-radius: 2rem;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

/*
 * 2. BUTTONS
 */
.btn {
  font-weight: 400;
  border: 1px solid transparent;
  padding: 0.4rem 1.8rem;
  font-size: 0.9rem;
  line-height: 1.5;
  border-radius: 2rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .btn {
    -webkit-transition: none;
    transition: none;
  }
}

.btn:focus, .btn.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(70, 128, 255, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(70, 128, 255, 0.25);
}

.btn.disabled, .btn:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
  background-image: none;
}

.btn-link {
  font-weight: 400;
  color: #009688;
}

.btn-link:hover {
  color: #004ef9;
  text-decoration: underline;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}

.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
}

.btn-primary {
  color: #fff;
  background-color: #009688;
  border-color: #009688;
}

.btn-primary:hover {
  color: #fff;
  background-color: #2066ff;
  border-color: #135dff;
}

.btn-primary:focus, .btn-primary.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(70, 128, 255, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(70, 128, 255, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #009688;
  border-color: #009688;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #135dff;
  border-color: #0654ff;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(70, 128, 255, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(70, 128, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary:focus, .btn-secondary.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-success:focus, .btn-success.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info:focus, .btn-info.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning:focus, .btn-warning.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger:focus, .btn-danger.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus, .btn-light.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-violet {
  color: #212529;
  background-color: #DF99CA;
  border-color: #DF99CA;
}

.btn-violet:hover {
  color: #212529;
  background-color: #d67cbb;
  border-color: #d372b6;
}

.btn-violet:focus, .btn-violet.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(223, 153, 202, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(223, 153, 202, 0.5);
}

.btn-violet.disabled, .btn-violet:disabled {
  color: #212529;
  background-color: #DF99CA;
  border-color: #DF99CA;
}

.btn-violet:not(:disabled):not(.disabled):active, .btn-violet:not(:disabled):not(.disabled).active,
.show > .btn-violet.dropdown-toggle {
  color: #212529;
  background-color: #d372b6;
  border-color: #d068b1;
}

.btn-violet:not(:disabled):not(.disabled):active:focus, .btn-violet:not(:disabled):not(.disabled).active:focus,
.show > .btn-violet.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(223, 153, 202, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(223, 153, 202, 0.5);
}

.btn-blue {
  color: #fff;
  background-color: #4C84FF;
  border-color: #4C84FF;
}

.btn-blue:hover {
  color: #fff;
  background-color: #266aff;
  border-color: #1961ff;
}

.btn-blue:focus, .btn-blue.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(76, 132, 255, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(76, 132, 255, 0.5);
}

.btn-blue.disabled, .btn-blue:disabled {
  color: #fff;
  background-color: #4C84FF;
  border-color: #4C84FF;
}

.btn-blue:not(:disabled):not(.disabled):active, .btn-blue:not(:disabled):not(.disabled).active,
.show > .btn-blue.dropdown-toggle {
  color: #fff;
  background-color: #1961ff;
  border-color: #0c58ff;
}

.btn-blue:not(:disabled):not(.disabled):active:focus, .btn-blue:not(:disabled):not(.disabled).active:focus,
.show > .btn-blue.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(76, 132, 255, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(76, 132, 255, 0.5);
}

.btn-green {
  color: #212529;
  background-color: #7CF29C;
  border-color: #7CF29C;
}

.btn-green:hover {
  color: #212529;
  background-color: #59ef82;
  border-color: #4eed79;
}

.btn-green:focus, .btn-green.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(124, 242, 156, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(124, 242, 156, 0.5);
}

.btn-green.disabled, .btn-green:disabled {
  color: #212529;
  background-color: #7CF29C;
  border-color: #7CF29C;
}

.btn-green:not(:disabled):not(.disabled):active, .btn-green:not(:disabled):not(.disabled).active,
.show > .btn-green.dropdown-toggle {
  color: #212529;
  background-color: #4eed79;
  border-color: #42ec70;
}

.btn-green:not(:disabled):not(.disabled):active:focus, .btn-green:not(:disabled):not(.disabled).active:focus,
.show > .btn-green.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(124, 242, 156, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(124, 242, 156, 0.5);
}

.btn-red {
  color: #fff;
  background-color: #F0404C;
  border-color: #F0404C;
}

.btn-red:hover {
  color: #fff;
  background-color: #ed1d2b;
  border-color: #eb1221;
}

.btn-red:focus, .btn-red.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(240, 64, 76, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(240, 64, 76, 0.5);
}

.btn-red.disabled, .btn-red:disabled {
  color: #fff;
  background-color: #F0404C;
  border-color: #F0404C;
}

.btn-red:not(:disabled):not(.disabled):active, .btn-red:not(:disabled):not(.disabled).active,
.show > .btn-red.dropdown-toggle {
  color: #fff;
  background-color: #eb1221;
  border-color: #df111f;
}

.btn-red:not(:disabled):not(.disabled):active:focus, .btn-red:not(:disabled):not(.disabled).active:focus,
.show > .btn-red.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(240, 64, 76, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(240, 64, 76, 0.5);
}

.btn-gray-100 {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-gray-100:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-gray-100:focus, .btn-gray-100.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-gray-100.disabled, .btn-gray-100:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-gray-100:not(:disabled):not(.disabled):active, .btn-gray-100:not(:disabled):not(.disabled).active,
.show > .btn-gray-100.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-gray-100:not(:disabled):not(.disabled):active:focus, .btn-gray-100:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray-100.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-gray-200 {
  color: #212529;
  background-color: #e9ecef;
  border-color: #e9ecef;
}

.btn-gray-200:hover {
  color: #212529;
  background-color: #d3d9df;
  border-color: #cbd3da;
}

.btn-gray-200:focus, .btn-gray-200.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(233, 236, 239, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(233, 236, 239, 0.5);
}

.btn-gray-200.disabled, .btn-gray-200:disabled {
  color: #212529;
  background-color: #e9ecef;
  border-color: #e9ecef;
}

.btn-gray-200:not(:disabled):not(.disabled):active, .btn-gray-200:not(:disabled):not(.disabled).active,
.show > .btn-gray-200.dropdown-toggle {
  color: #212529;
  background-color: #cbd3da;
  border-color: #c4ccd4;
}

.btn-gray-200:not(:disabled):not(.disabled):active:focus, .btn-gray-200:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray-200.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(233, 236, 239, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(233, 236, 239, 0.5);
}

.btn-gray-300 {
  color: #212529;
  background-color: #dee2e6;
  border-color: #dee2e6;
}

.btn-gray-300:hover {
  color: #212529;
  background-color: #c8cfd6;
  border-color: #c1c9d0;
}

.btn-gray-300:focus, .btn-gray-300.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(222, 226, 230, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(222, 226, 230, 0.5);
}

.btn-gray-300.disabled, .btn-gray-300:disabled {
  color: #212529;
  background-color: #dee2e6;
  border-color: #dee2e6;
}

.btn-gray-300:not(:disabled):not(.disabled):active, .btn-gray-300:not(:disabled):not(.disabled).active,
.show > .btn-gray-300.dropdown-toggle {
  color: #212529;
  background-color: #c1c9d0;
  border-color: #bac2cb;
}

.btn-gray-300:not(:disabled):not(.disabled):active:focus, .btn-gray-300:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray-300.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(222, 226, 230, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(222, 226, 230, 0.5);
}

.btn-gray-400 {
  color: #212529;
  background-color: #ced4da;
  border-color: #ced4da;
}

.btn-gray-400:hover {
  color: #212529;
  background-color: #b8c1ca;
  border-color: #b1bbc4;
}

.btn-gray-400:focus, .btn-gray-400.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(206, 212, 218, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(206, 212, 218, 0.5);
}

.btn-gray-400.disabled, .btn-gray-400:disabled {
  color: #212529;
  background-color: #ced4da;
  border-color: #ced4da;
}

.btn-gray-400:not(:disabled):not(.disabled):active, .btn-gray-400:not(:disabled):not(.disabled).active,
.show > .btn-gray-400.dropdown-toggle {
  color: #212529;
  background-color: #b1bbc4;
  border-color: #aab4bf;
}

.btn-gray-400:not(:disabled):not(.disabled):active:focus, .btn-gray-400:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray-400.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(206, 212, 218, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(206, 212, 218, 0.5);
}

.btn-gray-500 {
  color: #212529;
  background-color: #adb5bd;
  border-color: #adb5bd;
}

.btn-gray-500:hover {
  color: #212529;
  background-color: #98a2ac;
  border-color: #919ca6;
}

.btn-gray-500:focus, .btn-gray-500.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(173, 181, 189, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(173, 181, 189, 0.5);
}

.btn-gray-500.disabled, .btn-gray-500:disabled {
  color: #212529;
  background-color: #adb5bd;
  border-color: #adb5bd;
}

.btn-gray-500:not(:disabled):not(.disabled):active, .btn-gray-500:not(:disabled):not(.disabled).active,
.show > .btn-gray-500.dropdown-toggle {
  color: #212529;
  background-color: #919ca6;
  border-color: #8a95a1;
}

.btn-gray-500:not(:disabled):not(.disabled):active:focus, .btn-gray-500:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray-500.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(173, 181, 189, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(173, 181, 189, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus, .btn-dark.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-primary {
  color: #009688;
  background-color: transparent;
  background-image: none;
  border-color: #009688;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #009688;
  border-color: #009688;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(70, 128, 255, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(70, 128, 255, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #009688;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #009688;
  border-color: #009688;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(70, 128, 255, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(70, 128, 255, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #fff;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #fff;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-violet {
  color: #DF99CA;
  background-color: transparent;
  background-image: none;
  border-color: #DF99CA;
}

.btn-outline-violet:hover {
  color: #fff;
  background-color: #DF99CA;
  border-color: #DF99CA;
}

.btn-outline-violet:focus, .btn-outline-violet.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(223, 153, 202, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(223, 153, 202, 0.5);
}

.btn-outline-violet.disabled, .btn-outline-violet:disabled {
  color: #DF99CA;
  background-color: transparent;
}

.btn-outline-violet:not(:disabled):not(.disabled):active, .btn-outline-violet:not(:disabled):not(.disabled).active,
.show > .btn-outline-violet.dropdown-toggle {
  color: #212529;
  background-color: #DF99CA;
  border-color: #DF99CA;
}

.btn-outline-violet:not(:disabled):not(.disabled):active:focus, .btn-outline-violet:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-violet.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(223, 153, 202, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(223, 153, 202, 0.5);
}

.btn-outline-blue {
  color: #4C84FF;
  background-color: transparent;
  background-image: none;
  border-color: #4C84FF;
}

.btn-outline-blue:hover {
  color: #fff;
  background-color: #4C84FF;
  border-color: #4C84FF;
}

.btn-outline-blue:focus, .btn-outline-blue.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(76, 132, 255, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(76, 132, 255, 0.5);
}

.btn-outline-blue.disabled, .btn-outline-blue:disabled {
  color: #4C84FF;
  background-color: transparent;
}

.btn-outline-blue:not(:disabled):not(.disabled):active, .btn-outline-blue:not(:disabled):not(.disabled).active,
.show > .btn-outline-blue.dropdown-toggle {
  color: #fff;
  background-color: #4C84FF;
  border-color: #4C84FF;
}

.btn-outline-blue:not(:disabled):not(.disabled):active:focus, .btn-outline-blue:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-blue.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(76, 132, 255, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(76, 132, 255, 0.5);
}

.btn-outline-green {
  color: #7CF29C;
  background-color: transparent;
  background-image: none;
  border-color: #7CF29C;
}

.btn-outline-green:hover {
  color: #fff;
  background-color: #7CF29C;
  border-color: #7CF29C;
}

.btn-outline-green:focus, .btn-outline-green.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(124, 242, 156, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(124, 242, 156, 0.5);
}

.btn-outline-green.disabled, .btn-outline-green:disabled {
  color: #7CF29C;
  background-color: transparent;
}

.btn-outline-green:not(:disabled):not(.disabled):active, .btn-outline-green:not(:disabled):not(.disabled).active,
.show > .btn-outline-green.dropdown-toggle {
  color: #212529;
  background-color: #7CF29C;
  border-color: #7CF29C;
}

.btn-outline-green:not(:disabled):not(.disabled):active:focus, .btn-outline-green:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-green.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(124, 242, 156, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(124, 242, 156, 0.5);
}

.btn-outline-red {
  color: #F0404C;
  background-color: transparent;
  background-image: none;
  border-color: #F0404C;
}

.btn-outline-red:hover {
  color: #fff;
  background-color: #F0404C;
  border-color: #F0404C;
}

.btn-outline-red:focus, .btn-outline-red.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(240, 64, 76, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(240, 64, 76, 0.5);
}

.btn-outline-red.disabled, .btn-outline-red:disabled {
  color: #F0404C;
  background-color: transparent;
}

.btn-outline-red:not(:disabled):not(.disabled):active, .btn-outline-red:not(:disabled):not(.disabled).active,
.show > .btn-outline-red.dropdown-toggle {
  color: #fff;
  background-color: #F0404C;
  border-color: #F0404C;
}

.btn-outline-red:not(:disabled):not(.disabled):active:focus, .btn-outline-red:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-red.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(240, 64, 76, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(240, 64, 76, 0.5);
}

.btn-outline-gray-100 {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa;
}

.btn-outline-gray-100:hover {
  color: #fff;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-gray-100:focus, .btn-outline-gray-100.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-gray-100.disabled, .btn-outline-gray-100:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-gray-100:not(:disabled):not(.disabled):active, .btn-outline-gray-100:not(:disabled):not(.disabled).active,
.show > .btn-outline-gray-100.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-gray-100:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-100:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-gray-100.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-gray-200 {
  color: #e9ecef;
  background-color: transparent;
  background-image: none;
  border-color: #e9ecef;
}

.btn-outline-gray-200:hover {
  color: #fff;
  background-color: #e9ecef;
  border-color: #e9ecef;
}

.btn-outline-gray-200:focus, .btn-outline-gray-200.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(233, 236, 239, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(233, 236, 239, 0.5);
}

.btn-outline-gray-200.disabled, .btn-outline-gray-200:disabled {
  color: #e9ecef;
  background-color: transparent;
}

.btn-outline-gray-200:not(:disabled):not(.disabled):active, .btn-outline-gray-200:not(:disabled):not(.disabled).active,
.show > .btn-outline-gray-200.dropdown-toggle {
  color: #212529;
  background-color: #e9ecef;
  border-color: #e9ecef;
}

.btn-outline-gray-200:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-200:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-gray-200.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(233, 236, 239, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(233, 236, 239, 0.5);
}

.btn-outline-gray-300 {
  color: #dee2e6;
  background-color: transparent;
  background-image: none;
  border-color: #dee2e6;
}

.btn-outline-gray-300:hover {
  color: #fff;
  background-color: #dee2e6;
  border-color: #dee2e6;
}

.btn-outline-gray-300:focus, .btn-outline-gray-300.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(222, 226, 230, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(222, 226, 230, 0.5);
}

.btn-outline-gray-300.disabled, .btn-outline-gray-300:disabled {
  color: #dee2e6;
  background-color: transparent;
}

.btn-outline-gray-300:not(:disabled):not(.disabled):active, .btn-outline-gray-300:not(:disabled):not(.disabled).active,
.show > .btn-outline-gray-300.dropdown-toggle {
  color: #212529;
  background-color: #dee2e6;
  border-color: #dee2e6;
}

.btn-outline-gray-300:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-300:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-gray-300.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(222, 226, 230, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(222, 226, 230, 0.5);
}

.btn-outline-gray-400 {
  color: #ced4da;
  background-color: transparent;
  background-image: none;
  border-color: #ced4da;
}

.btn-outline-gray-400:hover {
  color: #fff;
  background-color: #ced4da;
  border-color: #ced4da;
}

.btn-outline-gray-400:focus, .btn-outline-gray-400.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(206, 212, 218, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(206, 212, 218, 0.5);
}

.btn-outline-gray-400.disabled, .btn-outline-gray-400:disabled {
  color: #ced4da;
  background-color: transparent;
}

.btn-outline-gray-400:not(:disabled):not(.disabled):active, .btn-outline-gray-400:not(:disabled):not(.disabled).active,
.show > .btn-outline-gray-400.dropdown-toggle {
  color: #212529;
  background-color: #ced4da;
  border-color: #ced4da;
}

.btn-outline-gray-400:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-400:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-gray-400.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(206, 212, 218, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(206, 212, 218, 0.5);
}

.btn-outline-gray-500 {
  color: #adb5bd;
  background-color: transparent;
  background-image: none;
  border-color: #adb5bd;
}

.btn-outline-gray-500:hover {
  color: #fff;
  background-color: #adb5bd;
  border-color: #adb5bd;
}

.btn-outline-gray-500:focus, .btn-outline-gray-500.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(173, 181, 189, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(173, 181, 189, 0.5);
}

.btn-outline-gray-500.disabled, .btn-outline-gray-500:disabled {
  color: #adb5bd;
  background-color: transparent;
}

.btn-outline-gray-500:not(:disabled):not(.disabled):active, .btn-outline-gray-500:not(:disabled):not(.disabled).active,
.show > .btn-outline-gray-500.dropdown-toggle {
  color: #212529;
  background-color: #adb5bd;
  border-color: #adb5bd;
}

.btn-outline-gray-500:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-500:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-gray-500.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(173, 181, 189, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(173, 181, 189, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-lg {
  padding: 0.5rem 2.2rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 2rem;
}

.btn-sm {
  padding: 0.25rem 1.8rem;
  font-size: 0.7875rem;
  line-height: 1.5;
  border-radius: 2rem;
}

/*
 * 3. TYPE
 */
body {
  font-family: "Poppins", sans-serif;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #F8F9FB;
}

a {
  color: #009688;
  text-decoration: none;
}

a:hover, a:focus {
  color: #004ef9;
  text-decoration: underline;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  line-height: 1.2;
  color: inherit;
}

h1,
.h1 {
  font-size: 2.25rem;
}

h2,
.h2 {
  font-size: 1.8rem;
}

h3,
.h3 {
  font-size: 1.575rem;
}

h4,
.h4 {
  font-size: 1.35rem;
}

h5,
.h5 {
  font-size: 1.125rem;
}

h6,
.h6 {
  font-size: 0.9rem;
}

.lead {
  font-size: 1.125rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.blockquote {
  padding: 0.5rem 1rem;
  margin-bottom: 2rem;
  font-size: 1.125rem;
  border-left: 5px solid #009688;
}

.blockquote-footer {
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014 \00A0";
}

.text-primary {
  color: #009688 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #135dff !important;
}

/*
 * 4. PAGINATION
 */
.page-item:first-child .page-link {
  border-top-left-radius: 2rem;
  border-bottom-left-radius: 2rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
}

.page-item.active .page-link {
  color: #fff;
  background-color: #009688;
  border-color: #009688;
}

.page-item.disabled .page-link {
  color: #6c757d;
  background-color: #fff;
  border-color: #dee2e6;
}

.page-link {
  padding: 0.5rem 0.75rem;
  line-height: 1.25;
  color: #009688;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link:hover, .page-link:focus {
  color: #004ef9;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.125rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 2rem;
  border-bottom-left-radius: 2rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.7875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 2rem;
  border-bottom-left-radius: 2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
}

/*
* 5. UTILITIES
*/
.bg-primary {
  background-color: #009688 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #135dff !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-violet {
  background-color: #DF99CA !important;
}

a.bg-violet:hover, a.bg-violet:focus,
button.bg-violet:hover,
button.bg-violet:focus {
  background-color: #d372b6 !important;
}

.bg-blue {
  background-color: #4C84FF !important;
}

a.bg-blue:hover, a.bg-blue:focus,
button.bg-blue:hover,
button.bg-blue:focus {
  background-color: #1961ff !important;
}

.bg-green {
  background-color: #7CF29C !important;
}

a.bg-green:hover, a.bg-green:focus,
button.bg-green:hover,
button.bg-green:focus {
  background-color: #4eed79 !important;
}

.bg-red {
  background-color: #F0404C !important;
}

a.bg-red:hover, a.bg-red:focus,
button.bg-red:hover,
button.bg-red:focus {
  background-color: #eb1221 !important;
}

.bg-gray-100 {
  background-color: #f8f9fa !important;
}

a.bg-gray-100:hover, a.bg-gray-100:focus,
button.bg-gray-100:hover,
button.bg-gray-100:focus {
  background-color: #dae0e5 !important;
}

.bg-gray-200 {
  background-color: #e9ecef !important;
}

a.bg-gray-200:hover, a.bg-gray-200:focus,
button.bg-gray-200:hover,
button.bg-gray-200:focus {
  background-color: #cbd3da !important;
}

.bg-gray-300 {
  background-color: #dee2e6 !important;
}

a.bg-gray-300:hover, a.bg-gray-300:focus,
button.bg-gray-300:hover,
button.bg-gray-300:focus {
  background-color: #c1c9d0 !important;
}

.bg-gray-400 {
  background-color: #ced4da !important;
}

a.bg-gray-400:hover, a.bg-gray-400:focus,
button.bg-gray-400:hover,
button.bg-gray-400:focus {
  background-color: #b1bbc4 !important;
}

.bg-gray-500 {
  background-color: #adb5bd !important;
}

a.bg-gray-500:hover, a.bg-gray-500:focus,
button.bg-gray-500:hover,
button.bg-gray-500:focus {
  background-color: #919ca6 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.border-primary {
  border-color: #009688 !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-violet {
  border-color: #DF99CA !important;
}

.border-blue {
  border-color: #4C84FF !important;
}

.border-green {
  border-color: #7CF29C !important;
}

.border-red {
  border-color: #F0404C !important;
}

.border-gray-100 {
  border-color: #f8f9fa !important;
}

.border-gray-200 {
  border-color: #e9ecef !important;
}

.border-gray-300 {
  border-color: #dee2e6 !important;
}

.border-gray-400 {
  border-color: #ced4da !important;
}

.border-gray-500 {
  border-color: #adb5bd !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.text-primary {
  color: #009688 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #135dff !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #545b62 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #1e7e34 !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #117a8b !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #d39e00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #bd2130 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #dae0e5 !important;
}

.text-violet {
  color: #DF99CA !important;
}

a.text-violet:hover, a.text-violet:focus {
  color: #d372b6 !important;
}

.text-blue {
  color: #4C84FF !important;
}

a.text-blue:hover, a.text-blue:focus {
  color: #1961ff !important;
}

.text-green {
  color: #7CF29C !important;
}

a.text-green:hover, a.text-green:focus {
  color: #4eed79 !important;
}

.text-red {
  color: #F0404C !important;
}

a.text-red:hover, a.text-red:focus {
  color: #eb1221 !important;
}

.text-gray-100 {
  color: #f8f9fa !important;
}

a.text-gray-100:hover, a.text-gray-100:focus {
  color: #dae0e5 !important;
}

.text-gray-200 {
  color: #e9ecef !important;
}

a.text-gray-200:hover, a.text-gray-200:focus {
  color: #cbd3da !important;
}

.text-gray-300 {
  color: #dee2e6 !important;
}

a.text-gray-300:hover, a.text-gray-300:focus {
  color: #c1c9d0 !important;
}

.text-gray-400 {
  color: #ced4da !important;
}

a.text-gray-400:hover, a.text-gray-400:focus {
  color: #b1bbc4 !important;
}

.text-gray-500 {
  color: #adb5bd !important;
}

a.text-gray-500:hover, a.text-gray-500:focus {
  color: #919ca6 !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #1d2124 !important;
}

.badge-primary {
  color: #fff;
  background-color: #009688;
}

.badge-primary[href]:hover, .badge-primary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #135dff;
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}

.badge-secondary[href]:hover, .badge-secondary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #545b62;
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

.badge-success[href]:hover, .badge-success[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1e7e34;
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

.badge-info[href]:hover, .badge-info[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #117a8b;
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

.badge-warning[href]:hover, .badge-warning[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #d39e00;
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

.badge-danger[href]:hover, .badge-danger[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #bd2130;
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

.badge-light[href]:hover, .badge-light[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #dae0e5;
}

.badge-violet {
  color: #212529;
  background-color: #DF99CA;
}

.badge-violet[href]:hover, .badge-violet[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #d372b6;
}

.badge-blue {
  color: #fff;
  background-color: #4C84FF;
}

.badge-blue[href]:hover, .badge-blue[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1961ff;
}

.badge-green {
  color: #212529;
  background-color: #7CF29C;
}

.badge-green[href]:hover, .badge-green[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #4eed79;
}

.badge-red {
  color: #fff;
  background-color: #F0404C;
}

.badge-red[href]:hover, .badge-red[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #eb1221;
}

.badge-gray-100 {
  color: #212529;
  background-color: #f8f9fa;
}

.badge-gray-100[href]:hover, .badge-gray-100[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #dae0e5;
}

.badge-gray-200 {
  color: #212529;
  background-color: #e9ecef;
}

.badge-gray-200[href]:hover, .badge-gray-200[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #cbd3da;
}

.badge-gray-300 {
  color: #212529;
  background-color: #dee2e6;
}

.badge-gray-300[href]:hover, .badge-gray-300[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #c1c9d0;
}

.badge-gray-400 {
  color: #212529;
  background-color: #ced4da;
}

.badge-gray-400[href]:hover, .badge-gray-400[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #b1bbc4;
}

.badge-gray-500 {
  color: #212529;
  background-color: #adb5bd;
}

.badge-gray-500[href]:hover, .badge-gray-500[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #919ca6;
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

.badge-dark[href]:hover, .badge-dark[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1d2124;
}

/*
  * 6. FORMS
  */
.form-control {
  padding: 0.4rem 0.8rem;
  font-size: 0.9rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 2rem;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .form-control {
    -webkit-transition: none;
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #c6d8ff;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(70, 128, 255, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(70, 128, 255, 0.25);
}

.form-control::-webkit-input-placeholder {
  color: #6c757d;
}

.form-control:-ms-input-placeholder {
  color: #6c757d;
}

.form-control::-ms-input-placeholder {
  color: #6c757d;
}

.form-control::placeholder {
  color: #6c757d;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
}

.form-control::-moz-placeholder {
  color: #999;
  font-weight: 300;
}

.form-control::-webkit-input-placeholder {
  color: #999;
  font-weight: 300;
}

.form-control:-ms-input-placeholder {
  color: #999;
  font-weight: 300;
}

select.form-control:not([size]):not([multiple]) {
  height: calc(2.15rem + 2px);
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-sm {
  padding: 0.25rem 0.8rem;
  font-size: 0.7875rem;
  line-height: 1.5;
  border-radius: 2rem;
}

select.form-control-sm:not([size]):not([multiple]) {
  height: calc(1.68125rem + 2px);
}

.form-control-lg {
  padding: 0.5rem 1.2rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 2rem;
}

select.form-control-lg:not([size]):not([multiple]) {
  height: calc(2.6875rem + 2px);
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.8);
  border-radius: .2rem;
}

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745;
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
.custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #28a745;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip, .was-validated
.custom-select:valid ~ .valid-feedback,
.was-validated
.custom-select:valid ~ .valid-tooltip,
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  background-color: #71dd8a;
}

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 1px #F8F9FB, 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
  box-shadow: 0 0 0 1px #F8F9FB, 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
  border-color: inherit;
}

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: .2rem;
}

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545;
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
.custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #dc3545;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip, .was-validated
.custom-select:invalid ~ .invalid-feedback,
.was-validated
.custom-select:invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  background-color: #efa2a9;
}

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 1px #F8F9FB, 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  box-shadow: 0 0 0 1px #F8F9FB, 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
  border-color: inherit;
}

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #009688;
}

.custom-control-input:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 1px #F8F9FB, 0 0 0 0.2rem rgba(70, 128, 255, 0.25);
  box-shadow: 0 0 0 1px #F8F9FB, 0 0 0 0.2rem rgba(70, 128, 255, 0.25);
}

.custom-control-input:active ~ .custom-control-label::before {
  color: #fff;
  background-color: #f9fbff;
}

.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-checkbox .custom-control-label::before {
  top: .1rem;
  border-radius: 0.3rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #009688;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  top: .1rem;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #009688;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(70, 128, 255, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(70, 128, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  top: .1rem;
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #009688;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  top: .1rem;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(70, 128, 255, 0.5);
}

/*
* 7.CODE
*/
code {
  font-size: 87.5%;
  color: #e83e8c;
}

/*
* 8. NAV
*/
.nav-link {
  padding: 0.5rem 1rem;
}

.nav-link.disabled {
  color: #6c757d;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #F8F9FB;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
}

.nav-pills .nav-link {
  border-radius: 2rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #009688;
}

/*
* 9. CARD
*/
.card {
  background-color: #fff;
  border: none;
  -webkit-box-shadow: 0 0.125rem 0.8rem rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.125rem 0.8rem rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.card-body {
  padding: 2rem;
}

.card-title {
  margin-bottom: 1.5rem;
}

.card-subtitle {
  margin-top: -0.75rem;
}

.card-link + .card-link {
  margin-left: 2rem;
}

.card-header {
  padding: 1.5rem 2rem;
  background-color: white;
  border-bottom: none;
  -webkit-box-shadow: 0 0.125rem 0.8rem rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.125rem 0.8rem rgba(0, 0, 0, 0.1);
}

.card-header:first-child {
  border-radius: calc(1rem - 1px) calc(1rem - 1px) 0 0;
}

.card-header-transparent {
  background-color: rgba(0, 0, 0, 0.3);
  border-bottom: none;
}

.card-footer {
  padding: 1.5rem 2rem;
  background-color: #f8f9fa;
  border-top: 1px solid #eee;
}

.card-footer:last-child {
  border-radius: 0 0 calc(1rem - 1px) calc(1rem - 1px);
}

.card-header-tabs {
  margin-right: -1rem;
  margin-bottom: -1.5rem;
  margin-left: -1rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -1rem;
  margin-left: -1rem;
}

.card-img-overlay {
  padding: 1.25rem;
}

.card-img-overlay-opacity {
  background: rgba(0, 0, 0, 0.2);
}

.card-img {
  border-radius: calc(1rem - 1px);
}

.card-img-top {
  border-top-left-radius: calc(1rem - 1px);
  border-top-right-radius: calc(1rem - 1px);
}

.card-img-bottom {
  border-bottom-right-radius: calc(1rem - 1px);
  border-bottom-left-radius: calc(1rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    margin-right: 15px;
    margin-left: 15px;
  }
}

/*
* 10. DROPDOWNS
*/
.dropdown-menu {
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.9rem;
  color: #212529;
  background-color: #fff;
  border: 1px solid transparent;
  -webkit-box-shadow: 0 0.125rem 0.8rem rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.125rem 0.8rem rgba(0, 0, 0, 0.1);
  border-radius: 0.3rem;
}

.dropdown-item {
  padding: 0.8rem 1.5rem;
  font-weight: 400;
  color: #212529;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  background-color: #f8f9fa;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  background-color: #009688;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
}

.btn-primary:hover {
  color: #fff;
  background-color: #ff9800;
  border-color: #009688;
  color: #009688 !important;
}

.errorMsg {
  background: #F03B2E;
  color: #fff !important;
  padding: 5px;
  font-size: 12px;
  font-weight: 600;
  position: relative;
}
.errorMsg:before {
  content: "";
  position: absolute;
  right: 1px;
  top: -8px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #F03B2E;
}

.issuccess-alert {
  position: fixed;
  bottom: 0;
  left: 0;
  font-size: 18px;
  background: #3B3F3D !important;
  color: #fff;
  z-index: 9999;
}
.iserror-alert {
  position: fixed;
  bottom: 0;
  left: 0;
  font-size: 18px;
  background: #a94442 !important;
  color: #fff;
  z-index: 9999;
}
button.btn.btn-success.react-bs-table-csv-btn.hidden-print {
  background: #0096884d;
  color: #009688;
  font-weight: 600;
  border-color: #009688;
}
.card-header.bk_green_bg {
  background: #009688 !important;
}

.card-header.bk_orange_bg {
  background: #ff9800 !important;
}

.card-header.bk_red_bg {
  background: #f34336 !important;
}

ul.nav.nav-tabs {
  font-size: 19px;
}

ul.nav.nav-tabs li {
  /* padding-right: 25px; */
  text-align: center !important;
  /* margin-right: 15px; */
  padding: 2px;
}

ul.nav.nav-tabs li.active {
  border-bottom: 5px solid #ff9800;
  border-top: 5px solid #ff9800;
  font-weight: 600;
}

.col-md-12.da-list-col {
  padding-left: 0 !important;
}

ul.nav.nav-tabs li a {
  color: #000000;
  font-weight: 500;
}

ul.nav.nav-tabs li.active a {
  color: #009688 !important;
}
a.nav-link.active {
  border-color: #009688 !important;
  color: #009688 !important;
  font-weight: 600 !important;
  width: 140px;
  border-bottom: transparent !important;
  border-top-width: 4px;
}
.col-md-12.ta-table-col {
  margin-top: 17px;
}
.count_absence_tab_link, .count_absence_tab_link.active {
  width: 230px !important;
}
#current_month_absence_link td, #last_month_absence_link td {
    text-align: left !important;
    vertical-align: middle;
}
span.count_absence_cell{
    font-size: 20px;
    background: #009688 !important;
}
span.count_absence_4{
  font-size: 20px;
}
button.add_to_screen_btn {
  position: fixed;
  top: 0;
  z-index: 9999;
  padding: 20px;
  text-align: center;
  width: 100%;
  background: #ff9800;
  color: #fff;
  font-weight: 600;
  border: 1px solid #ff9800 !important;
}
