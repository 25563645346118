.component-statistique {
}
.rad-info-box {
  margin-bottom: 16px;
  box-shadow: 1px 1px 2px 0 #ccc;
  padding: 20px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  background: white !important;
}
.rad-info-box i {
  display: block;
  background-clip: padding-box;
  margin-right: 15px;
  height: 60px;
  width: 60px;
  border-radius: 100%;
  line-height: 60px;
  text-align: center;
  font-size: 4.4em;
  position: absolute;
}
.rad-info-box .value,
.rad-info-box .heading {
  display: block;
  position: relative;
  color: #515d6e;
  text-align: right;
  z-index: 10;
}
.rad-info-box .heading {
  font-size: 1.2em;
  font-weight: 300;
  text-transform: uppercase;
}
.rad-info-box .value {
  font-size: 2.1em;
  font-weight: 600;
  margin-top: 5px;
}
.rad-list-group-item {
  margin: 5px 10px 25px 5px;
}
.rad-list-group-item:after {
  content: "";
  display: table;
}
.flat-theme .rad-info-box {
  box-shadow: none !important;
  border-radius: 4px;
}
@media screen and (max-width: 450px) {
  .rad-info-box i {
    opacity: 0.3;
  }
  .flat-theme .rad-toggle-btn {
    right: 20px !important;
  }
  .flat-theme .rad-top-nav-container .links:last-child {
    display: none;
  }
}

.page-title {
  margin-bottom: 20px;
  text-shadow: 1px 4px 6px #c6c6c6, 0 0 0 #000, 1px 4px 6px #c6c6c6;
}

.page-title-green {
  text-shadow: 1px 4px 6px #009688, 0 0 0 #009688, 1px 4px 6px #f8f9fb;
}
